import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

import CheckoutForm from '@/components/Checkout/FlowOne/checkoutUpdated.vue';
import CheckoutStep2 from '@/components/Checkout/FlowOne/checkoutStep2.vue';

import CheckoutOffer from '@/components/Checkout/FlowThree/checkoutOfferPage.vue';

import SpecialOffer from '@/components/Checkout/FlowThree/checkoutPage.vue';


import checkoutWithBasic from '@/components/Forms/FlowTwo/checkoutWithBasic.vue';
import checkoutWithBasicStep2 from '@/components/Checkout/FlowTwo/checkoutWithBasicStep2.vue';
import QualificationForm from '@/components/Forms/FlowTwo/QualificationForm.vue';


import SorryPage from '@/components/Forms/FlowTwo/SorryPage.vue';


import FullQuestionStageTwo from '@/components/Forms/FlowOne/FullQuestionStageTwo.vue'
//import QualifyingQuestion22 from '@/components/Forms/FlowOne/QualifyingQuestion.vue';

import QualifyingQuestion from '@/components/Forms/FlowOne/QualifyingQuestion.vue';
import DayQualification from '@/components/Forms/FlowOne/90dayQualification.vue';

//new style pages
import StyleThreeStepOne from '@/components/Forms/FlowThree/StepOne.vue';
import StyleThreeReportPage from '@/components/Forms/FlowThree/ReportPage.vue';
import ThreeCheckoutPage from '@/components/Checkout/FlowFour/CheckoutPage.vue';
import ChoosePlan from '@/components/Forms/FlowThree/ChoosePlan.vue'
import MedicalQuestions from '@/components/Forms/FlowThree/MedicalQuestions.vue'
import ChargeAsyncPage from '@/components/Forms/FlowThree/ChargeConfirm.vue'
import AsyncThanks from '@/components/Forms/FlowThree/AsyncThanks.vue';


import ThanksAppointment from '@/components/Forms/FlowOne/ThankYouAppointment.vue';
import RefillQuestions from '@/components/Forms/FlowOne/RefillQuestions.vue'
import ThankYou from '@/components/Forms/FlowOne/ThankYou.vue';

import MedicalThanks from '@/components/Forms/FlowOne/MedicalThanks.vue';


import CongratulationsPage from '@/components/Forms/FlowOne/CongratulationsPage.vue';
import AfterCheckout from '@/components/Forms/FlowOne/AfterCheckout.vue';

import Calendar from '@/components/Calendar/CalanderMain.vue';

import ThanksNavigator from '@/components/Forms/FlowOne/ThankYouNavigator.vue';

import HelpCalendar from '@/components/HelpCalendar/HelpCalendarNew.vue';

//import QuestionForm1 from '@/components/Webpages/backupForms/QuestionForm1.vue';
import HomePage from '@/components/Dashboard/homePage.vue';
import LoginPage from '@/components/Auth/LoginPage.vue';
import ForgotPassword from '@/components/Auth/ForgotPassword.vue';
import ResetPassword from '@/components/Auth/ResetPassword.vue';

import DiscountPage100 from '@/components/DiscountPages/DiscountPage.vue';
import DiscountPagePreSell from '@/components/DiscountPages/DiscountPagePreSell.vue';
import PerkSpot110 from '@/components/DiscountPages/PerkSpot110.vue';

import PerkSpot200 from '@/components/DiscountPages/PerkSpot200.vue';
import ValPackDiscount from '@/components/DiscountPages/ValPackDiscount.vue';

import DiscountPage100Ray from '@/components/DiscountPages/DiscountPage100Ray.vue';

import ReportPage from '@/components/Forms/FlowOne/reportPage.vue';
import reportOffers from '@/components/Forms/FlowOne/reportOffers.vue';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  hash: false,
  routes: [
    {
      path: '/',
      name: 'QualifyingQuestion',
      component: QualifyingQuestion,
      meta: {
        requiresAuth: false,
        guest: true
      }
    }, 
    {
      path: '/90day',
      name: 'DayQualification',
      component: DayQualification,
      meta: {
        requiresAuth: false,
        guest: true
      }
    }, 
    {
      path: '/weightloss/payment',
      name: 'ThreeCheckoutPage',
      component: ThreeCheckoutPage,
      meta: {
        requiresAuth: false,
        guest: true
      }
    }, 
    {
      path: '/weightloss/enroll',
      name: 'StyleThreeStepOne',
      component: StyleThreeStepOne,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/weightloss/report',
      name: 'StyleThreeReportPage',
      component: StyleThreeReportPage,
      meta: {
        requiresAuth: false,
        guest: true
      }
    }, 
    {
      path: '/weightloss/medical-profile',
      name: 'MedicalQuestions',
      component: MedicalQuestions,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/weightloss/plan',
      name: 'ChoosePlan',
      component: ChoosePlan,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/weightloss/async',
      name: 'ChargeAsync',
      component: ChargeAsyncPage,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/weightloss/async-thanks',
      name: 'AsyncThanks',
      component: AsyncThanks,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/report',
      name: 'ReportPage',
      component: ReportPage,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/report-discount',
      name: 'ReportPageDiscount',
      component: ReportPage,
      meta: {
        requiresAuth: false,
        guest: true
      }
    }, 
    {
      path: '/weight-loss-report',
      name: 'reportOffers',
      component: reportOffers,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/glp1',
      name: 'QualifyingGLP',
      component: QualifyingQuestion,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/sema50',
      name: 'Sema50',
      component: QualifyingQuestion,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/sema100',
      name: 'Sema100',
      component: QualifyingQuestion,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/wellness-program',
      name: 'DirectToAppointment',
      component: QualifyingQuestion,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/congratulations',
      name: 'CongratulationsPage',
      component: CongratulationsPage,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/thanks-you',
      name: 'AfterCheckout',
      component: AfterCheckout,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/get-to-know',
      name: 'FullQuestionStageTwo',
      component: FullQuestionStageTwo,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/subitted-success',
      name: 'MedicalThanks',
      component: MedicalThanks,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/get-qualified',
      name: 'QualificationForm',
      component: QualificationForm,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/re-visit',
      name: 'RefillQuestions',
      component: RefillQuestions,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/re-thanks',
      name: 'ThankYouRevisit',
      component: ThankYou,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/get-started',
      name: 'checkoutWithBasic',
      component: checkoutWithBasic,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/payment',
      name: 'checkoutWithBasicStep2',
      component: checkoutWithBasicStep2,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/checkout',
      name: 'CheckoutForm',
      component: CheckoutForm,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/checkout-step2',
      name: 'CheckoutStep2',
      component: CheckoutStep2,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/checkout-offer',
      name: 'CheckoutOffer',
      component: CheckoutOffer,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/special-offer',
      name: 'SpecialOffer',
      component: SpecialOffer,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/schedule',
      name: 'Calendar',
      component: Calendar,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/help-appointment',
      name: 'HelpCalendar',
      component: HelpCalendar,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/help-thanks',
      name: 'ThanksNavigator',
      component: ThanksNavigator,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/schedule-confirm',
      name: 'ThanksAppointment',
      component: ThanksAppointment,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/home',
      name: 'HomePage',
      component: HomePage,
      meta: {
        requiresAuth: true,
        guest: false
      }
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: LoginPage,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/sorry',
      name: 'SorryPage',
      component: SorryPage,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/password-reset/:token',
      name: 'ResetPassword',
      component: ResetPassword,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/discount-50',
      name: 'DiscountPage50',
      component: DiscountPage100,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/discount-100',
      name: 'DiscountPage100',
      component: DiscountPage100,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/discount50pre',
      name: 'DiscountPagePreSell',
      component: DiscountPagePreSell,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/easy50pre',
      name: 'DiscountPageSkinny',
      component: DiscountPagePreSell,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/valpak50',
      name: 'ValPackDiscount50',
      component: ValPackDiscount,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/valpak100',
      name: 'ValPackDiscount100',
      component: ValPackDiscount,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/PerkSpot110',
      name: 'PerkSpot110',
      component: PerkSpot110,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/PerkSpot200',
      name: 'PerkSpot200',
      component: PerkSpot200,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/easy200pre',
      name: 'PerkSpot200',
      component: PerkSpot200,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/easy100pre',
      name: 'DiscountPage100Ray',
      component: DiscountPage100Ray,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/discount100pre',
      name: 'DiscountPage100S',
      component: DiscountPage100Ray,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/sema100pre',
      name: 'sema100pre',
      component: DiscountPage100Ray,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/*',
      name: '404',
      component: QualifyingQuestion,
      meta: {
        guest: true
      }
    },
  ],

})

router.beforeEach((to, from, next) => {
  const currentParams = from.query;
  const targetParams = to.query;
  const mergedParams = { ...currentParams, ...targetParams };
  const paramsDiffer = JSON.stringify(targetParams) !== JSON.stringify(mergedParams);
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.authToken == null) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  } else if (to.name == "LoginPage" && store.getters.authToken !== null) {
    next({
      path: '/home'
    })
  } else if (paramsDiffer) {
    next({
      ...to,
      query: mergedParams, // Include merged parameters
    });
  } else {
    next();
  }
})
export default router;
